import React from "react";
import { Route } from "react-router-dom";

import bootstrap from "_common/bootstrapper";

import Root from "./containers/Root";
import reducer from "./reducers";

const rootComponent = <Route component={Root} />;
bootstrap({
  reducer,
  rootComponent,
});
