import { gettext } from "django-i18n";
import React from "react";
import clsx from "clsx";

import StyleIcon from "@deprecated/material-ui/svg-icons/image/style";

/**
 * Used to display a huge message when no items are available. Usually used in lieu of an empty table.
 */

export type EmptyStateProps = React.ComponentPropsWithoutRef<"div"> & {
  heading: React.ReactNode;
  message?: React.ReactNode;
  icon?: React.ReactElement;
};

const EmptyState = ({
  heading,
  icon = <StyleIcon />,
  message,
  ...props
}: EmptyStateProps) => {
  const newIcon = React.cloneElement(icon, {
    className: "sde-empty-state-icon",
    style: {
      width: "80px",
      height: "80px",
      fill: "#e6e9eb",
    },
  });

  return (
    <div {...props} className={clsx("sde-empty-state", props.className)}>
      {newIcon}
      <h2 className="sde-empty-state-heading">
        {heading || gettext("We're Sorry")}
      </h2>
      <div className="sde-empty-state-message">
        {message !== undefined
          ? message
          : gettext("It's lonely, there are no items here.")}
      </div>
    </div>
  );
};

export default EmptyState;
