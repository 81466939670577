/*
  SAML HTTP-POST Redirect Page.

  This is not attached to the router and is only rendered
  by the post_binding_form.html template.
*/
import { gettext } from "django-i18n";
import { map } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import RedirectIcon from "@deprecated/material-ui/svg-icons/content/forward";

import EmptyState from "_common/components/empty-state";

class Root extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.handleFormSubmit();
  }

  handleFormSubmit() {
    if (this.formRef.current) {
      this.formRef.current.submit();
    }
  }

  renderSAMLForm() {
    const { targetUrl, params } = this.props;

    return (
      <form
        action={targetUrl}
        method="post"
        name="SSO_Login"
        ref={this.formRef}
      >
        {map(params, (value, key) => (
          <input key={key} name={key} type="hidden" value={value} />
        ))}
      </form>
    );
  }

  renderMessage() {
    return (
      <span>
        <a onClick={this.handleFormSubmit.bind(this)}>
          {gettext("Click here")}
        </a>
        {gettext(" if you're not automatically redirected within seconds.")}
      </span>
    );
  }

  render() {
    return (
      <div>
        {this.renderSAMLForm()}
        <EmptyState
          heading={gettext("Redirecting to your SSO provider")}
          icon={<RedirectIcon />}
          message={this.renderMessage()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  targetUrl: state.django.target_url,
  params: state.django.params,
});

export default connect(mapStateToProps)(Root);
